// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./i18n";
import "./App.css";

import { TonConnectUIProvider } from "@tonconnect/ui-react";

const App: React.FC = () => {
  return (
    <TonConnectUIProvider manifestUrl="https://bilodev.com/wp-content/uploads/2024/07/tonconnect-manifest.json">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </TonConnectUIProvider>
  );
};

export default App;
