import React from "react";
import { useTranslation } from "react-i18next";
import gif from "../assets/gif/loading.gif";
const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
      className="main-bg">
      <img src={gif} alt="gif" style={{ width: "90%" }} />

      <div className="as" style={{ height: "10%" }}></div>
      <h1 className="white">{t("Maintenance_mode")}</h1>
      <button
        onClick={() => {
          window.open("https://t.me/+RZdup6dovOU1YjMy", "_blank");
        }}
        style={{ width: "80%", height: 46, backgroundColor: "transparent" }}
        className="btn-brm-bg">
        {t("joinMessage")}
      </button>
    </div>
  );
};

export default Home;
